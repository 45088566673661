<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import ReportsHeader from "@/components/reports-header";
import moment from "moment";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    ReportsHeader,
  },
  data() {
    return {
      filterdPeriod: "As of ",
      totalLiabilityAndEquity: 0,
      balanceSheet: [],
      nonFinancialAssets: [],
      assets: [],
      liabilities: [],
      currentAssets: [],
      totalCurrentAssets: 0,
      fixedAssets: [],
      totalFixedAssets: 0,
      netIncome: 0,
      totalExpenses: 0,
      totalRevenues: 0,
      totalLiabiliteValue: 0,
      accountingPeriods: [],
      data: {},
      isMonth: false,

      // equity
      equities: [],
      totalEquity: 0,

      // start prev period vars
      // prevTotalLiabilityAndEquity: 0,
      // prevBalanceSheet: [],
      // prevNonFinancialAssets: [],
      // prevAssets: [],
      // prevLiabilities: [],
      // prevCurrentAssets: [],
      // prevTotalCurrentAssets: 0,
      // prevFixedAssets: [],
      // prevTotalFixedAssets: 0,
      // prevNetIncome: 0,
      // prevTotalExpenses: 0,
      // prevTotalRevenues: 0,
      // prevTotalLiabiliteValue: 0,
      // end prev period vars
    };
  },
  methods: {
    getBalanceSheet() {
      if (this.data.to) {
        this.isMonth = true;
        this.filterdPeriod = `${moment(this.data.to)
          .endOf("month")
          .format("DD")}-${this.data.to.split("-").reverse().join("-")}`;
      } else {
        this.isMonth = false;
      }
      if (!this.isMonth) {
        this.accountingPeriods.forEach((period) => {
          if (period.id == this.data.accounting_period_id) {
            this.filterdPeriod = `${period.end_date
              .split("-")
              .reverse()
              .join("-")}`;
          }
        });
      }
      this.http
        .post("transactions/balance-sheet", {
          accounting_period_id: this.data.accounting_period_id,
          from: this.data.from,
          to: moment(this.data.to).endOf("month").format("YYYY-MM-DD"),
        })
        .then((resp) => {
          // start select period balance sheet
          this.netIncome = parseFloat(resp.data[3]?.components);

          this.totalExpenses = 0;
          this.totalRevenues = 0;

          this.totalFixedAssets = 0;
          this.totalCurrentAssets = 0;

          this.totalEquity = 0;

          this.totalLiabiliteValue = 0;
          this.totalLiabilityAndEquity = 0;

          this.balanceSheet[0] = resp.data[0];
          this.balanceSheet[1] = resp.data[1];
          this.balanceSheet[2] = resp.data[2];

          this.balanceSheet[0].components = JSON.parse(
            this.balanceSheet[0].components
          );
          this.balanceSheet[1].components = JSON.parse(
            this.balanceSheet[1].components
          );
          this.balanceSheet[2].components = JSON.parse(
            this.balanceSheet[2].components
          );

          this.currentAssets[0] = this.balanceSheet[0].components[0];
          this.fixedAssets[0] = this.balanceSheet[0].components[1];

          this.currentAssets[0].children.forEach((asset) => {
            this.totalCurrentAssets += asset.value;
          });
          this.fixedAssets[0]?.children.forEach((asset) => {
            this.totalFixedAssets += asset.value;
          });

          this.totalRevenues += this.totalFixedAssets + this.totalCurrentAssets;
          this.assets[0] = this.balanceSheet[0];
          this.liabilities = this.balanceSheet[1];
          this.equities = this.balanceSheet[2];

          this.liabilities?.components.forEach((component) => {
            component?.children?.forEach((child) => {
              child.value = (child?.value ? child?.value : 0) * -1;
              this.totalLiabiliteValue += parseFloat(
                child?.value ? child?.value : 0
              );
            });
          });
          this.equities?.components.forEach((component) => {
            component?.children?.forEach((child) => {
              child.value = (child?.value ? child?.value : 0) * -1;
              this.totalEquity += parseFloat(child?.value ? child?.value : 0);
            });
          });
          console.log(this.equities);
          console.log(this.totalEquity);
          // end select period balance sheet

          // start prev period balance sheet

          // end prev year balancesheet
        });
    },
    getAccountingPeriods() {
      this.http.get("accounting-peroids").then((resp) => {
        if (resp) {
          this.accountingPeriods = resp.data;
        }
      });
    },
  },
  created() {
    const current_user = JSON.parse(localStorage.currentUser);
    this.branch_id = current_user.branch_id;
    // this.getBalanceSheet();
    this.getAccountingPeriods();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
      :title="$t('balance_sheet.name')"
    />
    <form @submit.prevent="getBalanceSheet()">
      <div class="row">
        <div class="col">
          <label class="inline" for="fromJobTitle">{{
            $t("report.period")
          }}</label>
          <select
            v-model="data.accounting_period_id"
            type="date"
            class="form-control"
            id="fromJobTitle"
          >
            <option
              :value="period.id"
              v-for="period in accountingPeriods"
              :key="period"
            >
              {{
                period?.start_date +
                ` ${this.$i18n.locale == "ar" ? "الى" : "To"} ` +
                period?.end_date
              }}
            </option>
          </select>
        </div>

        <div class="col">
          <label class="inline" for="toJobTitle">{{
            $t("report.month")
          }}</label>
          <input
            v-model="data.to"
            type="month"
            class="form-control fromto"
            id="toJobTitle"
          />
        </div>
        <div class="col-auto" style="top: 16px">
          <button type="submit" class="btn btn-primary">
            {{ $t("popups.search") }}
          </button>
        </div>
        <div class="col"></div>
      </div>
    </form>

    <div class="row mt-4" v-if="assets.length > 0">
      <div class="card">
        <div class="card-body">
          <div class="report-layout">
            <ReportsHeader
              v-if="assets.length > 0"
              :reportName="$t('reports.balance_sheet')"
              :filterdPeriod="
                $i18n.locale == 'ar'
                  ? 'إعتبارا من ' + filterdPeriod
                  : 'As of ' + filterdPeriod
              "
            />
            <div class="row">
              <table class="col table">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>
                      {{
                        $i18n.locale == "ar"
                          ? assets[0]?.name
                          : assets[0]?.name_en
                      }}
                    </th>
                  </tr>
                  <tr v-for="asset in currentAssets" :key="asset">
                    <th>
                      <span style="margin-inline-start: 20px">
                        {{ $i18n.locale == "ar" ? asset.name : asset.name_en }}
                      </span>
                    </th>
                  </tr>
                  <tr
                    v-for="component in currentAssets[0]?.children"
                    :key="component"
                  >
                    <td>
                      <div style="margin-inline-start: 50px">
                        {{
                          $i18n.locale == "ar"
                            ? component?.name
                            : component?.name_en
                        }}
                      </div>
                    </td>
                    <td>
                      <div>{{ component?.value.toLocaleString() }}</div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div style="margin-inline-start: 50px">
                        {{ $t("reports.total_fixed") }}
                      </div>
                    </th>
                    <td>
                      <div class="total-style">
                        {{ totalCurrentAssets.toLocaleString() }}
                      </div>
                    </td>
                  </tr>
                  <tr v-for="asset in fixedAssets" :key="asset">
                    <th>
                      <span style="margin-inline-start: 20px">
                        {{
                          $i18n.locale == "ar" ? asset?.name : asset?.name_en
                        }}
                      </span>
                    </th>
                  </tr>
                  <tr
                    v-for="component in fixedAssets[0]?.children"
                    :key="component"
                  >
                    <td>
                      <div style="margin-inline-start: 50px">
                        {{
                          $i18n.locale == "ar"
                            ? component?.name
                            : component?.name_en
                        }}
                      </div>
                    </td>
                    <td>
                      {{ Math.abs(component?.value).toLocaleString() }}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div style="margin-inline-start: 50px">
                        {{ $t("reports.total_current") }}
                      </div>
                    </th>
                    <th>
                      <div>
                        {{ parseFloat(totalFixedAssets).toLocaleString() }}
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th>{{ $t("reports.total_assets") }}</th>
                    <th class="total-style">
                      {{ parseFloat(totalRevenues).toLocaleString() }}
                    </th>
                  </tr>
                </tbody>
              </table>
              <table class="col table">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>
                      {{
                        $i18n.locale == "ar"
                          ? liabilities?.name
                          : liabilities?.name_en
                      }}
                    </th>
                  </tr>
                  <tr
                    v-for="liability in balanceSheet[1]?.components"
                    :key="liability"
                  >
                    <td>
                      <div style="margin-inline-start: 20px; font-weight: bold">
                        {{
                          $i18n.locale == "ar"
                            ? liability.name
                            : liability.name_en
                        }}
                      </div>
                      <tr
                        style="margin-inline-start: 50px"
                        v-for="child in liability?.children"
                        class="d-flex justify-content-between"
                        :key="child"
                      >
                        <td>
                          <div>
                            {{
                              $i18n.locale == "ar"
                                ? child?.name
                                : child?.name_en
                            }}
                          </div>
                        </td>
                        <td>
                          <div>
                            {{
                              parseFloat(
                                Math.abs(child?.value)
                              ).toLocaleString()
                            }}
                          </div>
                        </td>
                      </tr>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div
                        class="d-flex justify-content-between"
                        style="margin-inline-start: 20px"
                      >
                        <span>
                          {{ $t("reports.total_liabilities") }}
                        </span>
                        <p class="total-style">
                          {{ parseFloat(totalLiabiliteValue).toLocaleString() }}
                        </p>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {{
                        $i18n.locale == "ar"
                          ? balanceSheet[2]?.name
                          : balanceSheet[2]?.name_en
                      }}
                    </th>
                  </tr>
                  <tr v-for="equity in equities?.components" :key="equity">
                    <td>
                      <div style="margin-inline-start: 20px; font-weight: bold">
                        {{
                          $i18n.locale == "ar" ? equity.name : equity.name_en
                        }}
                      </div>
                      <tr
                        style="margin-inline-start: 50px"
                        v-for="child in equity?.children"
                        class="d-flex justify-content-between"
                        :key="child"
                      >
                        <td>
                          <div>
                            {{
                              $i18n.locale == "ar"
                                ? child?.name
                                : child?.name_en
                            }}
                          </div>
                        </td>
                        <td>
                          <div>
                            {{
                              parseFloat(
                                Math.abs(child?.value)
                              ).toLocaleString()
                            }}
                          </div>
                        </td>
                      </tr>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div class="d-flex justify-content-between">
                        <span>
                          {{ $t("reports.net_income") }}
                        </span>
                        <p class="total-style">
                          {{ parseFloat(netIncome).toLocaleString() }}
                        </p>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <div class="d-flex justify-content-between">
                        <span>
                          {{ $t("reports.total_equity") }}
                        </span>
                        <p class="total-style">
                          {{
                            (
                              parseFloat(netIncome) + parseFloat(totalEquity)
                            ).toLocaleString()
                          }}
                        </p>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <div class="d-flex justify-content-between">
                      <span style="font-weight: bold">
                        {{ $t("reports.total_equity_liability") }}
                      </span>
                      <p class="total-style">
                        {{
                          (
                            parseFloat(totalEquity) +
                            parseFloat(totalLiabiliteValue) +
                            parseFloat(netIncome)
                          ).toLocaleString()
                        }}
                      </p>
                    </div>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
/* .report-layout {
  padding: 10px;
} */
/*
.table,
td {
  border: 1px solid black;
}
*/
</style>
